import './SignUpForm.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';

import CheckboxSignupContainer from '../../../components/checkbox-container/checkbox-signup';
import LocationAutoComplete from '../../../components/location-auto-complete';
import UserContext from '../../../contexts/userContext';
import { firebaseAnalytics } from '../../../root/firebaseConfig';
import categorydata from '../components/categorydata';
import onlinecategorydata from '../components/onlinecategorydata';
import stepData from './stepsData.json';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';

const SignUpForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { useData, setUserData } = useContext(UserContext);
  const [robot, setRobot] = useState({ isRobot: true, showRobotErr: false });

  const [formData, setFormData] = useState(null);
  const { step_data } = stepData;
  const { handleSubmit, register, errors, watch, ...form } = useForm();
  const [showError, setShowError] = useState({ message: null });
  const [check, onCheck] = useState(false);
  const [coordinates, setCoordinates] = useState({});
  const [showBaddressError, setBaddressError] = useState(false);
  const [description, setDescription] = useState('');
  const [data, setData] = useState(categorydata);
  const [count, setCount] = useState(0);
  const [selectedCategory, setSelectedCategory] = React.useState();
  const [selectedSubcategory, setSelectedSubcategory] = React.useState();
  const [showCalendly, setShowCalendly] = React.useState(false);
  const availableSubcategory = data.category.find((c) => c.machine_name === selectedCategory);

  useEffect(
    () => {
      if(check || watch('baddress')) {
        setBaddressError(false);
        setData(onlinecategorydata);
      }
    },
    [check],
    watch('baddress'),
  );

  useEffect(
    () => {
      if(!check || watch('baddress')) {
        setData(categorydata);
      }
    },
    [check],
    watch('baddress'),
  );

  useEffect(() => {
    register({ name: 'baddress', required: true });
  }, []);

  const onInputChange = (event) => {
    setDescription(event.target.value);
  };
  const shortDescChange = (event) => {
    setCount(event.target.value.length);
  };

  const handleForm = async (data) => {
    data.type = 1;
    data.lat = coordinates.lat;
    data.long = coordinates.long;

    if(!check && (!data.lat || !data.long)) return setBaddressError(true);

    data.btype = 'online';
    if(data.baddress && !check) {
      data.btype = 'offline';
    }

    data.claimed = true;

    setIsLoading(true);
    setFormData(data);
    setShowCalendly(true);
  };


  const loginUserHandler = (user) => {
    setUserData({
      isLogin: true,
      uname: user.udata.email,
      token: user.token,
      type: user.udata.type,
      id: user.udata.id,
      btype: user.udata.btype,
    });
    Cookies.set('authtoken', user.token);
    Cookies.set('uname', user.udata.email);
    Cookies.set('id', user.udata.id);
    firebaseAnalytics.logEvent('Signup', {
      user_id: user.udata.id,
    });
  };

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: async (e) => {
      if(formData) {
        try {
          const res = await axios.post(process.env.RAZZLE_RUNTIME_APP_BASEURL + '/users/create', formData);

          if(res.status === 200) {
            loginUserHandler(res?.data);
          }
        } catch(error) {
          if(error.response.data) {
            setShowError(error.response.data);
          } else {
            setShowError({ message: 'Something went wrong' });
          }
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  const closeCalendlyHandler = () => {
    setShowCalendly(false);
  };

  return (
    <div className='form_wrapper custom_container'>
      {typeof document !== 'undefined' && (
        <PopupModal
          url='https://calendly.com/momnpophub'
          open={showCalendly}
          text='Schedule time with me'
          branding={true}
          rootElement={document.getElementById('root')}
          onModalClose={closeCalendlyHandler}
          pageSettings={{
            backgroundColor: '#0069ff',
            textColor: '#ffffff',
          }}
          prefill={{
            firstName: watch('fname'),
            lastName: watch('lname'),
            name: `${watch('fname')} ${watch('lname')}`.trim(),
            email: watch('email'),
          }}
        />
      )}
      <Row>
        <Col sm={6}>
          <Row className='align-items-top'>
            <Col sm={12}>
              <h2 className='signup_heading'>Free Business Signup</h2>
              <p className='form_indicate_text'>
                Join <span>100's of U.S. based small businesses</span> who have
                <span> joined Mom n Pop Hub</span> and are benefiting with increased visibility via
                our mobile app.
              </p>
              <div className='app_platform'>
                <a href='https://play.google.com/store/apps/details?id=com.momnpophub'>
                  <img src='./playstore.png' />
                </a>
                <a href='https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666'>
                  <img src='./applestore.png' />
                </a>
              </div>
              <Form
                autoComplete='off'
                className='signup-form'
                onSubmit={handleSubmit((data) => handleForm(data))}
              >
                <Row className='form_content'>
                  {/* Form Section */}
                  <Col sm={12}>
                    <Row>
                      {/* User's FirstName and LastName Starts*/}
                      <Col sm={12}>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className='text_input_field'>
                              <Form.Control
                                autoComplete='chrome-off'
                                name='fname'
                                ref={register({ required: true })}
                                type='text'
                                className={`input ${errors.fname ? 'is-invalid' : ''} shadow-none`}
                                placeholder='Business Owner First Name *'
                              />
                              <span className='checkbox-label'>
                                This information is not published online.
                              </span>
                              {errors.fname && (
                                <p style={{ color: 'red' }}>First name is required</p>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className='text_input_field'>
                              <Form.Control
                                autoComplete='chrome-off'
                                name='lname'
                                ref={register({ required: true })}
                                type='text'
                                className={`input ${errors.lname ? 'is-invalid' : ''} shadow-none`}
                                placeholder='Business Owner Last Name *'
                              />
                              <span className='checkbox-label'>
                                This information is not published online.
                              </span>
                              {errors.lname && (
                                <p style={{ color: 'red' }}>Last name is required</p>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>

                      {/* User's Business Name */}
                      <Col sm={12}>
                        <Form.Group className='text_input_field'>
                          <Form.Control
                            autoComplete='chrome-off'
                            name='bname'
                            ref={register({ required: true })}
                            type='text'
                            className={`input ${errors.bname ? 'is-invalid' : ''} shadow-none`}
                            placeholder='Business Name *'
                          />
                          {errors.bname && (
                            <p style={{ color: 'red' }}>Business Name is required</p>
                          )}
                        </Form.Group>
                      </Col>

                      {/* User's Business Phone*/}

                      <Col sm={12}>
                        <Form.Group className='text_input_field'>
                          <Form.Control
                            autoComplete='chrome-off'
                            name='phonenumber'
                            ref={register({
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                            })}
                            type='tel'
                            className={`input ${errors.phonenumber ? 'is-invalid' : ''
                              } shadow-none`}
                            placeholder='Business Contact Phone Number *'
                          />
                          <span className='checkbox-label'>
                            New customers can contact on this number
                          </span>
                          {errors.phonenumber && errors.phonenumber.type === 'required' && (
                            <p style={{ color: 'red' }}>Phone Number is required</p>
                          )}
                          {errors.phonenumber &&
                            (errors.phonenumber.type === 'minLength' ||
                              errors.phonenumber.type === 'maxLength') && (
                              <p style={{ color: 'red' }}>Phone Number must be 10 digits</p>
                            )}
                        </Form.Group>
                      </Col>

                      <Col sm={12}>
                        <Form.Group className='text_input_field'>
                          <Form.Control
                            autoComplete='chrome-off'
                            name='businessOwnerPhone'
                            ref={register({
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                            })}
                            type='tel'
                            className={`input ${errors.businessOwnerPhone ? 'is-invalid' : ''
                              } shadow-none`}
                            placeholder='Business Owner Phone Number *'
                          />
                          <span className='checkbox-label'>
                            This information is not published online.
                          </span>
                          {errors.businessOwnerPhone &&
                            errors.businessOwnerPhone.type === 'required' && (
                              <p style={{ color: 'red' }}>Phone Number is required</p>
                            )}
                          {errors.businessOwnerPhone &&
                            (errors.businessOwnerPhone.type === 'minLength' ||
                              errors.businessOwnerPhone.type === 'maxLength') && (
                              <p style={{ color: 'red' }}>Phone Number must be 10 digits</p>
                            )}
                        </Form.Group>
                      </Col>

                      {/* User's Email address */}
                      <Col sm={12}>
                        <Form.Group className='text_input_field'>
                          <Form.Control
                            autoComplete='chrome-off'
                            name='email'
                            ref={register({
                              required: true,
                              pattern: /\S+@\S+\.\S+/,
                            })}
                            type='email'
                            className={`input ${errors.email ? 'is-invalid' : ''} shadow-none`}
                            placeholder='Business Owner Email Address *'
                          />
                          <span className='checkbox-label'>
                            This information is not published online.
                          </span>
                          {errors.email && errors.email.type === 'required' && (
                            <p style={{ color: 'red' }}>Email is required</p>
                          )}
                          {errors.email && errors.email.type === 'pattern' && (
                            <p style={{ color: 'red' }}>Invalid email</p>
                          )}
                        </Form.Group>
                      </Col>

                      {/* User's business address */}
                      <Col sm={12}>
                        <div className='business_checkbox'>
                          <CheckboxSignupContainer
                            labetText='We are an online business only'
                            checked={check}
                            change={() => onCheck(!check)}
                          />
                        </div>
                        <Form.Group className='text_input_field'>
                          <LocationAutoComplete
                            name='baddress'
                            form={form}
                            errors={errors}
                            placeholder='Business Address *'
                            getCoordinates={setCoordinates}
                            check={check}
                            disabled={check}
                            resetError={setBaddressError}
                          />
                          <span className='checkbox-label'>
                            Information is published on your business profile page
                          </span>
                          {showBaddressError && ( //KACKEY--------
                            <p style={{ color: 'red' }}>Business Address is Invalid</p>
                          )}
                        </Form.Group>
                      </Col>

                      {/* User's Business Website */}
                      <Col sm={12}>
                        <Form.Group className='text_input_field'>
                          <Form.Control
                            autoComplete='chrome-off'
                            name='website'
                            ref={register({
                              required: check,
                              pattern:
                                /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/,
                            })}
                            type='text'
                            className={`input ${errors.website ? 'is-invalid' : ''}  shadow-none`}
                            placeholder={`Business Website ${check ? '*' : ''}`}
                          />
                          <span className='checkbox-label'>
                            Information is published on your business profile page
                          </span>

                          {errors.website && errors.website.type === 'required' && check && (
                            <p style={{ color: 'red' }}>Business Website is required</p>
                          )}

                          {errors.website && errors.website.type === 'pattern' && (
                            <p style={{ color: 'red' }}>Invalid Website</p>
                          )}
                        </Form.Group>
                      </Col>

                      <Col sm={12}>
                        <Form.Group className='text_input_field'>
                          <select
                            placeholder='Category'
                            name='category'
                            className={`input category ${errors.subcategory ? 'is-invalid' : ''}`}
                            //className="input shadow-none"
                            ref={register({ required: true })}
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                          >
                            <option value=''>Choose Category</option>
                            {data.category.map((value, key) => {
                              return (
                                <option value={value.machine_name} key={key}>
                                  {value.name}
                                </option>
                              );
                            })}
                          </select>
                          {errors.category && <p style={{ color: 'red' }}>Category is required</p>}
                          <select
                            placeholder='subcategory'
                            name='subcategory'
                            className={`input category select subcategory ${errors.subcategory ? 'is-invalid' : ''
                              }`}
                            ref={register({ required: true })}
                            value={selectedSubcategory}
                            onChange={(e) => setSelectedSubcategory(e.target.value)}
                          >
                            <option value=''>Choose Sub Category</option>
                            {availableSubcategory?.subcategory.map((e, key) => {
                              return (
                                <option value={e.machine_name} key={key}>
                                  {e.name}
                                </option>
                              );
                            })}
                          </select>
                          {errors.subcategory && (
                            <p style={{ color: 'red' }}>Sub-category is required</p>
                          )}
                        </Form.Group>
                      </Col>

                      <Col sm={12}>
                        <Form.Group className='text_input_field'>
                          <Form.Control
                            autoComplete='chrome-off'
                            name='shortdescription'
                            ref={register({
                              required: true,
                              minLength: 1,
                              maxLength: 160,
                            })}
                            type='text'
                            onChange={shortDescChange}
                            className={`input ${errors.shortdescription ? 'is-invalid' : ''
                              } shadow-none`}
                            placeholder='Short Description *'
                          />
                          <span className='checkbox-label'>
                            This information helps your search ranking
                          </span>
                          <p style={{ marginTop: '8px', color: '#474747' }}>
                            Maximum 160 characters allowed
                            <span style={{ color: 'red' }}>
                              &nbsp;remained characters: {160 - count}
                            </span>
                          </p>

                          {errors.shortdescription &&
                            errors.shortdescription.type === 'required' && (
                              <p style={{ color: 'red' }}>Short description is required</p>
                            )}
                          {errors.shortdescription &&
                            (errors.shortdescription.type === 'minLength' ||
                              errors.shortdescription.type === 'maxLength') && (
                              <p style={{ color: 'red' }}>Maximum 160 characters allowed.</p>
                            )}
                        </Form.Group>
                      </Col>

                      <Col sm={12} className='description_input_wrapper'>
                        <textarea
                          rows={4}
                          autoComplete='new-password'
                          name='description'
                          value={description}
                          placeholder='Business Description'
                          onChange={onInputChange}
                          className='description_input'
                          ref={register}
                        />
                        <span
                          className='checkbox-label'
                          style={{ display: 'block', marginTop: '-2px' }}
                        >
                          This information helps your search ranking & is published on your profile
                          page
                        </span>
                      </Col>

                      <Col sm={12}>
                        <Row>
                          {/* User's password */}
                          <Col sm={12}>
                            <Form.Group className='text_input_field'>
                              <Form.Control
                                autoComplete='chrome-off'
                                name='password'
                                ref={register({
                                  required: true,
                                  pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                })}
                                type='password'
                                className={`input ${errors.password ? 'is-invalid' : ''
                                  } shadow-none`}
                                placeholder='Enter Password *'
                              />
                              {errors.password && errors.password.type === 'required' && (
                                <p style={{ color: 'red' }}>Password is required</p>
                              )}
                              {errors.password && errors.password.type === 'pattern' && (
                                <>
                                  <p style={{ color: 'red' }}>
                                    Strong password is required! Password must include lowercase,
                                    uppercase and numeric character and must be 8 or longer
                                    charcters
                                  </p>
                                </>
                              )}
                            </Form.Group>
                          </Col>

                          {/* User's password confirm*/}
                          <Col sm={12}>
                            <Form.Group className='text_input_field'>
                              <Form.Control
                                autoComplete='new-password'
                                name='confirmPassword'
                                ref={register({
                                  required: true,
                                  validate: (value) => value === watch('password'),
                                })}
                                type='password'
                                className={`input ${errors.confirmPassword ? 'is-invalid' : ''
                                  } shadow-none`}
                                placeholder='Confirm Password *'
                              />
                              {errors.confirmPassword &&
                                errors.confirmPassword.type === 'required' && (
                                  <p style={{ color: 'red' }}>Confirm password is required</p>
                                )}
                              {errors.confirmPassword &&
                                errors.confirmPassword.type === 'validate' && (
                                  <p style={{ color: 'red' }}>Passwords do not match</p>
                                )}
                            </Form.Group>
                          </Col>
                          <Col sm={12}>
                            <Form.Group>
                              <ReCAPTCHA
                                onChange={(value) => {
                                  setRobot({
                                    isRobot: false,
                                    showRobotErr: false,
                                  });
                                }}
                                onExpired={(expired) => {
                                  setRobot({
                                    isRobot: true,
                                    showRobotErr: false,
                                  });
                                }}
                                sitekey='6Lfea-IZAAAAAAygbpzANjWTq2jzMJNNRSdmidaO'
                              />
                              {robot.showRobotErr && (
                                <p style={{ color: 'red' }}>Verify you are not robot</p>
                              )}
                            </Form.Group>
                          </Col>
                          {/* <Col sm={12}>
                            <p className='payment_detail_text'>
                              Credit card will be requested in the
                              following screen to start a <b>30-days</b> free
                              trial.
                            </p>
                          </Col> */}
                          <Col
                            sm={12}
                            className='d-flex justify-content-md-start justify-content-center'
                          >
                            <Button
                              type='submit'
                              variant='btn'
                              className='submit_btn'
                              disabled={isLoading}
                            >
                              Free Business Signup
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
        {/* Trail Steps */}
        <Col sm={6}>
          <div className='step_wrapper'>
            {step_data?.map((item, index) => (
              <div className='step_item' key={index}>
                <h2>{item?.id}.</h2>
                <h4>{item?.title}</h4>
                <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SignUpForm;
